import apiPasarela from "../apiPasarela";

const getPromociones = async (params) => {
  const endpoint = "/administracion/promociones";
  const { data } = await apiPasarela.get(endpoint, { params });
  return data;
};

const createPromocion = async (payload) => {
  const endpoint = "/administracion/promocion";
  const { data } = await apiPasarela.post(endpoint, payload);
  return data;
};

const updatePromocion = async (payload) => {
  const endpoint = "/administracion/promocion";
  const { data } = await apiPasarela.put(endpoint, payload);
  return data;
};

const getBines = async () => {
  const endpoint = "/administracion/bin-tarjetas";
  const { data } = await apiPasarela.get(endpoint);
  return data;
};

const createBin = async (payload) => {
  const endpoint = "/administracion/bin-tarjeta";
  const { data } = await apiPasarela.post(endpoint, payload);
  return data;
};

const createPlan = async (payload) => {
  const endpoint = "/administracion/mediopagoinstallments";
  const { data } = await apiPasarela.post(endpoint, payload);
  return data;
};

export default {
  getPromociones,
  createPromocion,
  updatePromocion,
  getBines,
  createBin,
  createPlan,
};

<template>
  <v-dialog
    v-model="internalDialog"
    width="500"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title
        class="text-h6 font-weight-medium "
        style="font-family: 'Montserrat' !important; font-weight: 700 !important;"
      >
        Formulario de Plan de Cuotas
      </v-card-title>

      <v-form ref="form" class="d-flex flex-column gap-5 px-8 pb-3">
        <v-text-field
          label="Nombre"
          v-model="form.nombre"
          :rules="nombreRules"
        ></v-text-field>

        <v-textarea
          label="Descripción"
          v-model="form.descripcion"
          :rules="descRules"
          :rows="4"
        ></v-textarea>
      </v-form>

      <div class="mt-5">
        <v-divider></v-divider>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">
          Cerrar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="crearPlanCuota()"
          :loading="loading"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import promocionesService from "../../../services/administrar/promociones";

export default {
  name: "FormPromocion",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    promocion_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      internalDialog: this.dialog,
      form: {
        nombre: "",
        descripcion: "",
      },
      loading: false,

      //rules
      nombreRules: [
        (v) => !!v || "Nombre es requerido",
        (v) => v.length >= 6 || "Nombre debe tener almenos 6 caracteres",
      ],
      descRules: [
        (v) => !!v || "Descripción es requerido",
        (v) => v.length >= 12 || "Descripción debe tener almenos 12 caracteres",
      ],
    };
  },

  methods: {
    async crearPlanCuota() {
      if (this.$refs.form.validate()) {
        let payload = {
          nombre: this.form.nombre,
          descripcion: this.form.descripcion,
          promociones_id: this.promocion_id,
        };

        try {
          this.loading = true;
          await promocionesService.createPlan(payload);
          this.loading = false;

          this.$emit("close");
          this.$toastr.s(`Plan de cuotas creado con exito`);
        } catch (error) {
          this.loading = false;
          this.$toastr.e("Error al crear el plan, intente nuevamente");
          console.error("Error al crear el plan:", error);
        }
      }
    },
  },
};
</script>

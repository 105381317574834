<template>
  <v-dialog
    v-model="internalDialog"
    width="620"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title
        class="text-h6 font-weight-medium "
        style="font-family: 'Montserrat' !important; font-weight: 700 !important;"
      >
        Formulario de Promoción
      </v-card-title>

      <v-form ref="form" class="d-flex flex-column gap-5 px-7 pb-3">
        <v-text-field
          label="Nombre"
          v-model="form.nombre"
          :rules="nombreRules"
          hide-details="auto"
        ></v-text-field>

        <div class="d-flex items-center" style="height: 50px;">
          <div class="d-flex items-center w-50">
            <v-checkbox
              v-model="form.cuotasSinInteres"
              hide-details
              class="m-0"
            />
            <p
              class="m-0 mt-1 opacity-80 cursor-pointer"
              style="font-size: 15px;"
              @click="form.cuotasSinInteres = !form.cuotasSinInteres"
            >
              Cuotas sin interés
            </p>
          </div>

          <v-text-field
            label="Porcentaje de descuento"
            type="number"
            v-model="form.porcentaje"
            hide-details="auto"
            prepend-inner-icon="mdi-percent-outline"
            class="w-50"
            v-if="!form.cuotasSinInteres"
            :rules="porcentajeRules"
          ></v-text-field>
        </div>

        <div>
          <p class="mb-2 text-start opacity-80">
            <v-icon style="font-size: 20px">mdi-calculator</v-icon>
            Cuotas permitidas
          </p>

          <Select2
            v-model="form.cuotas"
            :options="cuotas"
            :settings="{
              language: { noResults: () => 'No se encontraron resultados' },
              placeholder: 'Favor de seleccionar cuotas',
              multiple: true,
              width: '100%',
            }"
            style="height: 30px !important;"
          />
        </div>
      </v-form>

      <div class="mx-6 mt-8">
        <p class="mb-2 text-start opacity-80">
          <v-icon style="font-size: 20px">mdi-credit-card-outline</v-icon>
          Selección de bines
        </p>

        <v-btn class="mb-7 opacity-70" outlined block @click="dialogBin = true">
          <v-icon class="mr-2">mdi-magnify</v-icon>
          Buscar bin
        </v-btn>

        <p class="mb-1 text-start mx-1 mt-3">
          Bines seleccionados:
          <span class="!font-bold ml-2">{{ selectedBines.length }}</span>
        </p>
        <v-simple-table
          class="border "
          :class="errors.bin ? 'border-danger' : ''"
          height="220px"
          fixed-header
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Descripción
                </th>
                <th class="text-left">
                  Bin
                </th>
                <th class="text-center">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="bin in selectedBines" :key="bin.id">
                <td class="text-left">{{ bin.descripcion }}</td>
                <td class="text-left">{{ bin.numero }}</td>
                <td>
                  <v-btn icon outlined color="error" @click="removeBin(bin)">
                    <v-icon style="font-size: 20px;">
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="flex justify-start mt-1" v-if="errors.bin">
          <small class="text-danger text-start !w-full">
            Debes seleccionar al menos un bin
          </small>
        </div>
      </div>

      <div class="mt-7">
        <v-divider></v-divider>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">
          Cerrar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="crearPromocion()"
          :loading="loading"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Componente Bines-->
    <Bines
      v-if="dialogBin"
      :dialog.sync="dialogBin"
      @close="
        (bines) => {
          dialogBin = false;
          selectedBines = [...bines, ...selectedBines];
          errors.bin = false;
        }
      "
    />
  </v-dialog>
</template>

<script>
import promocionesService from "../../../services/administrar/promociones";
import Bines from "./Bines.vue";
export default {
  name: "FormPromocion",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    promocion: {
      type: Object,
      required: false,
    },
  },
  components: { Bines },
  data() {
    return {
      cuotas: [],
      internalDialog: this.dialog,
      dialogBin: false,
      selectedBines: [],
      form: {
        nombre: "",
        cuotas: ["1"],
        cuotasSinInteres: false,
        porcentaje: 0,
        bines: [],
      },

      loading: false,

      //rules
      nombreRules: [
        (v) => !!v || "Nombre es requerido",
        (v) => v.length >= 6 || "Nombre debe tener almenos 6 caracteres",
      ],
      porcentajeRules: [
        (v) => !!v || "Porcentaje es requerido",
        (v) => v >= 0 || "Porcentaje debe ser mayor a 0",
        (v) => v <= 100 || "Porcentaje debe ser menor a 100",
      ],

      errors: {
        bin: false,
      },
    };
  },

  created() {
    this.cuotas = Array.from({ length: 36 }, (_, i) => i + 1);

    setTimeout(() => {
      this.form.cuotas = [];

      // Si se esta editando
      if (this.promocion?.id) {
        const {
          nombre,
          porcentaje,
          cuota_sin_interes: cuotasSinInteres,
          cuotas,
        } = this.promocion;

        const cuotasString = cuotas.map(({ cuota }) => cuota.toString());

        this.form = {
          nombre,
          porcentaje,
          cuotasSinInteres,
          cuotas: cuotasString,
        };

        this.selectedBines = this.promocion.bines;
      }
    });
  },

  methods: {
    async crearPromocion() {
      if (this.selectedBines.length == 0) {
        this.errors.bin = true;
        return;
      }

      if (this.$refs.form.validate()) {
        const binesId = this.selectedBines.map((bin) => bin.id);
        const cuotas = this.form.cuotas.map((cuota) => Number(cuota));

        let payload = {
          nombre: this.form.nombre,
          porcentaje: Number(this.form.porcentaje),
          cuota_sin_interes: this.form.cuotasSinInteres,
          cuotas,
          bines: binesId,
        };

        if (this.promocion?.id) {
          payload.id = this.promocion.id;
        }

        try {
          this.loading = true;

          this.promocion?.id
            ? await promocionesService.updatePromocion(payload)
            : await promocionesService.createPromocion(payload);

          this.loading = false;
          this.$emit("close");
          this.$emit("refreshPromociones");

          this.$toastr.s(
            `Promoción ${
              this.promocion?.id ? "actualizada" : "creada"
            } con éxito`
          );
        } catch (error) {
          this.loading = false;
          this.$toastr.e("Error al crear la promocion, intente nuevamente");
          console.error("Error al crear la promocion:", error);
        }
      }
    },

    removeBin(bin) {
      this.selectedBines = this.selectedBines.filter((b) => b.id !== bin.id);
    },
  },
};
</script>

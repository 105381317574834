var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticStyle:{"margin-top":"100px"}},[_c('div',{staticClass:"d-flex justify-end mx-3 my-3"},[_c('button',{staticClass:"btn btn-success ",on:{"click":function($event){_vm.showFormDialog = true}}},[_c('span',{staticClass:"text-sm font-semibold"},[_vm._v(" Nueva Promoción ")])])]),_c('LayoutPage',{attrs:{"title":"Promociones"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.promociones,"items-per-page":_vm.rowsPerPage,"loading":_vm.loading,"loading-text":"Cargando... Espere por favor","no-results-text":"No se encontraron registros","no-data-text":"No se encontraron registros","calculate-widths":"","hide-overlay":"","hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.cuota_sin_interes",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.cuota_sin_interes ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.cuota_sin_interes ? "Si" : "No")+" ")])]}},{key:"item.porcentaje",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.porcentaje > 0 ? ((item.porcentaje) + " %") : "-")+" ")]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({directives:[{name:"attrs",rawName:"v-attrs",value:(attrs),expression:"attrs"}],attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.verDetalle(item)}}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver promoción")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({directives:[{name:"attrs",rawName:"v-attrs",value:(attrs),expression:"attrs"}],staticClass:"ml-3",attrs:{"fab":"","x-small":"","dark":"","color":"orange"},on:{"click":function($event){return _vm.verFormEdit(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar promoción")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({directives:[{name:"attrs",rawName:"v-attrs",value:(attrs),expression:"attrs"}],staticClass:"ml-3",attrs:{"fab":"","x-small":"","color":"info"},on:{"click":function($event){return _vm.verCuentas(item)}}},on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,true)},[_c('span',[_vm._v("Asignar cuenta")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({directives:[{name:"attrs",rawName:"v-attrs",value:(attrs),expression:"attrs"}],staticClass:"ml-3",attrs:{"fab":"","x-small":"","color":"teal","dark":""},on:{"click":function($event){return _vm.verFormPlan(item)}}},on),[_c('v-icon',[_vm._v("mdi-cash")])],1)]}}],null,true)},[_c('span',[_vm._v("Plan de cuotas")])])]}},{key:"footer",fn:function(){return [(false)?_c('v-row',{staticClass:"no-gutters mt-2 mx-2"},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticClass:"mt-2"},[_vm._v("Mostrar")]),_c('v-menu',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.rowsPerPage)+" "),_c('v-icon',[_vm._v("arrow_drop_down")])],1)]}}],null,false,2705691625)},[_c('v-list',_vm._l((_vm.rowsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateRowsPerPage(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1)],1)],1):_vm._e()]},proxy:true}],null,true)})],1),(_vm.showDetalleDialog)?_c('VerDetalle',{attrs:{"promocion":_vm.selectedPromocion,"dialog":_vm.showDetalleDialog},on:{"update:dialog":function($event){_vm.showDetalleDialog=$event},"close":function () {
          _vm.showDetalleDialog = false;
          _vm.selectedPromocion = null;
        }}}):_vm._e(),(_vm.showFormDialog)?_c('FormPromocion',{attrs:{"dialog":_vm.showFormDialog,"promocion":_vm.selectedPromocion},on:{"update:dialog":function($event){_vm.showFormDialog=$event},"close":function () {
          _vm.showFormDialog = false;
          _vm.selectedPromocion = null;
        },"refreshPromociones":_vm.getPromociones}}):_vm._e(),(_vm.showPlanDialog)?_c('FormPlancuota',{attrs:{"dialog":_vm.showPlanDialog,"promocion_id":_vm.selectedPromocion.id},on:{"update:dialog":function($event){_vm.showPlanDialog=$event},"close":function () {
          _vm.showPlanDialog = false;
          _vm.selectedPromocion = null;
        }}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container>
    <div style="margin-top: 100px;">
      <div class="d-flex justify-end mx-3 my-3">
        <button class="btn btn-success " @click="showFormDialog = true">
          <span class="text-sm font-semibold">
            Nueva Promoción
          </span>
        </button>
      </div>

      <LayoutPage title="Promociones">
        <v-data-table
          :headers="headers"
          :items="promociones"
          :items-per-page="rowsPerPage"
          :loading="loading"
          loading-text="Cargando... Espere por favor"
          no-results-text="No se encontraron registros"
          no-data-text="No se encontraron registros"
          calculate-widths
          hide-overlay
          hide-default-footer
          disable-sort
        >
          <template v-slot:[`item.cuota_sin_interes`]="{ item }">
            <v-chip :color="item.cuota_sin_interes ? 'green' : 'red'" dark>
              {{ item.cuota_sin_interes ? "Si" : "No" }}
            </v-chip>
          </template>

          <template v-slot:[`item.porcentaje`]="{ item }">
            {{ item.porcentaje > 0 ? `${item.porcentaje} %` : "-" }}
          </template>

          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-small
                  @click="verDetalle(item)"
                  v-on="on"
                  v-attrs="attrs"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver promoción</span>
            </v-tooltip>

            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-small
                  dark
                  @click="verFormEdit(item)"
                  class="ml-3"
                  color="orange"
                  v-on="on"
                  v-attrs="attrs"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar promoción</span>
            </v-tooltip>

            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-small
                  @click="verCuentas(item)"
                  class="ml-3"
                  color="info"
                  v-on="on"
                  v-attrs="attrs"
                >
                  <v-icon>mdi-account</v-icon>
                </v-btn>
              </template>
              <span>Asignar cuenta</span>
            </v-tooltip>

            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-small
                  @click="verFormPlan(item)"
                  class="ml-3"
                  color="teal"
                  dark
                  v-on="on"
                  v-attrs="attrs"
                >
                  <v-icon>mdi-cash</v-icon>
                </v-btn>
              </template>
              <span>Plan de cuotas</span>
            </v-tooltip>
          </template>

          <!-- cantidad de registros por pagina -> actualizar numero de registros por pagina -->
          <template v-slot:footer>
            <v-row class="no-gutters mt-2 mx-2" v-if="false">
              <v-flex xs12>
                <span class="mt-2">Mostrar</span>
                <v-menu top>
                  <template v-slot:activator="{ on }">
                    <v-btn dark text color="primary" class="ml-2" v-on="on">
                      {{ rowsPerPage }}
                      <v-icon>arrow_drop_down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(number, index) in rowsPerPageArray"
                      :key="index"
                      @click="updateRowsPerPage(number)"
                    >
                      <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-row>
          </template>
        </v-data-table>
      </LayoutPage>

      <!-- Componente VerDetalle -->
      <VerDetalle
        v-if="showDetalleDialog"
        :promocion="selectedPromocion"
        :dialog.sync="showDetalleDialog"
        @close="
          () => {
            showDetalleDialog = false;
            selectedPromocion = null;
          }
        "
      />

      <!-- Modal para crear una nueva promocion -->
      <FormPromocion
        v-if="showFormDialog"
        :dialog.sync="showFormDialog"
        :promocion="selectedPromocion"
        @close="
          () => {
            showFormDialog = false;
            selectedPromocion = null;
          }
        "
        @refreshPromociones="getPromociones"
      />

      <!-- Modal para crear una nueva promocion -->
      <FormPlancuota
        v-if="showPlanDialog"
        :dialog.sync="showPlanDialog"
        :promocion_id="selectedPromocion.id"
        @close="
          () => {
            showPlanDialog = false;
            selectedPromocion = null;
          }
        "
      />
    </div>
  </v-container>
</template>

<script>
import LayoutPage from "../../../components/LayoutPage.vue";
import promosionesService from "../../../services/administrar/promociones.js";
import VerDetalle from "../../../components/Administrar/Promociones/VerDetalle.vue";
import FormPromocion from "../../../components/Administrar/Promociones/FormPromocion.vue";
import FormPlancuota from "../../../components/Administrar/Promociones/FormPlancuota.vue";

export default {
  name: "Promociones",
  components: { LayoutPage, VerDetalle, FormPromocion, FormPlancuota },
  data() {
    return {
      promociones: [],
      selectedPromocion: null,
      showDetalleDialog: false, // Estado del diálogo de detalles
      showFormDialog: false,
      showPlanDialog: false,

      //data table
      headers: [
        { text: "Promocion", value: "nombre" },
        { text: "Porcentaje", value: "porcentaje" },
        { text: "Sin interés", value: "cuota_sin_interes" },
        { text: "Acciones", value: "acciones" },
      ],
      loading: false,
      rowsPerPage: 10,
      rowsPerPageArray: [10, 30, 50],
    };
  },

  created() {
    this.getPromociones();
  },

  methods: {
    async getPromociones() {
      try {
        this.loading = true;
        const resp = await promosionesService.getPromociones();
        this.promociones = resp.data?.promociones || [];
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },

    verDetalle(promocion) {
      this.selectedPromocion = promocion; // Asigna la promoción seleccionada
      this.showDetalleDialog = true; // Abre el diálogo de detalles
    },

    verFormEdit(promocion) {
      this.selectedPromocion = promocion;
      this.showFormDialog = true;
    },

    verFormPlan(promocion) {
      this.selectedPromocion = promocion;
      this.showPlanDialog = true;
    },

    verCuentas(promocion) {
      this.$router.push({
        name: "PromocionesCuentas",
        params: { promocion_id: promocion.id },
      });
    },
  },
};
</script>

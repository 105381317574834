<template>
  <v-dialog
    v-model="internalDialog"
    @click:outside="$emit('close', [])"
    :width="570"
  >
    <v-card>
      <v-card-title
        style="font-family: 'Montserrat' !important;"
        class="!font-bold"
      >
        {{ tab === "form" ? "Agregar bin" : "Bines" }}
      </v-card-title>

      <div class="mx-6 mb-6" v-if="tab === 'table'">
        <div class="d-flex items-center gap-5">
          <v-text-field
            v-model="search"
            label="Buscar bin"
            hide-details
            prepend-inner-icon="mdi-magnify"
            class="my-0"
          ></v-text-field>

          <v-btn fab small color="primary" class="mt-2" @click="tab = 'form'">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>

        <p class="mb-1 mt-6 text-start mx-1">
          <span class="opacity-80 mr-2"> Bines seleccionados:</span>
          <span class="!font-bold">{{ selectedBines.length }}</span>
        </p>
        <v-data-table
          :headers="headers"
          :items="filteredBines"
          :loading="loading"
          loading-text="Cargando... Espere por favor"
          no-results-text="No se encontraron registros"
          no-data-text="No se encontraron registros"
          show-select
          checkbox-color="green"
          item-key="id"
          calculate-widths
          hide-overlay
          hide-default-footer
          disable-sort
          class="border "
          v-model:selected="selectedBines"
          :items-per-page="pagination.itemsPerPage"
          :page.sync="pagination.page"
        >
          <template v-slot:footer>
            <div class="flex justify-end mb-2">
              <v-pagination
                v-model="pagination.page"
                :length="Math.ceil(bines.length / pagination.itemsPerPage)"
                color="primary"
              ></v-pagination>
            </div>
          </template>
        </v-data-table>
      </div>

      <v-form v-if="tab === 'form'" ref="form">
        <div class="mx-8">
          <v-text-field
            v-model="newBin.descripcion"
            label="Descripción"
            class="mt-0 mb-2"
            :rules="[validateDescripcion]"
          ></v-text-field>

          <v-text-field
            v-model="newBin.numero"
            label="Bin"
            class="mb-4"
            placeholder="Ej: 123456"
            type="number"
            :rules="[validateNumero]"
          ></v-text-field>
        </div>
      </v-form>

      <div class="flex justify-end py-2 border-t">
        <div class="mx-6">
          <v-btn
            text
            @click="tab === 'table' ? $emit('close', []) : resetForm()"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="tab === 'form' ? addBin() : $emit('close', selectedBines)"
            :loading="loading"
          >
            Agregar
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import promocionesService from "../../../services/administrar/promociones";

export default {
  name: "Bines",
  props: ["dialog"],
  data() {
    return {
      tab: "table",
      internalDialog: this.dialog,
      headers: [
        { text: "Descripción", value: "descripcion" },
        { text: "Número", value: "numero" },
      ],
      bines: [],
      selectedBines: [],
      loading: false,
      search: "",
      newBin: {
        descripcion: "",
        numero: "",
      },
      binesTotal: 0,
      pagination: {
        itemsPerPage: 4, // Mostrar 5 registros por página
        page: 1, // Página actual
      },
    };
  },
  computed: {
    filteredBines() {
      if (!this.search) return this.bines;
      return this.bines.filter((bin) =>
        `${bin.descripcion} ${bin.numero}`
          .toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
  },
  created() {
    this.getBines();
  },
  methods: {
    validateDescripcion(value) {
      if (!value) return "La descripción es obligatoria.";
      if (value.length < 6)
        return "La descripción debe tener al menos 6 caracteres.";
      return true;
    },
    validateNumero(value) {
      if (!value) return "El número Bin es obligatorio.";
      if (!/^\d+$/.test(value)) return "El número debe ser válido.";
      if (value.length < 6) return "El número debe tener al menos 6 dígitos.";
      return true;
    },

    async getBines() {
      this.loading = true;
      try {
        const { data } = await promocionesService.getBines();
        this.bines = data.bines;
      } catch (error) {
        console.error("Error al cargar los bines:", error);
      } finally {
        this.loading = false;
      }
    },
    async addBin() {
      const form = this.$refs.form;

      // Validar todo el formulario antes de proceder
      if (form.validate()) {
        try {
          this.loading = true;
          const { data } = await promocionesService.createBin({
            ...this.newBin,
            numero: Number(this.newBin.numero),
          });

          this.bines.unshift(data);
          this.selectedBines.push(data);
          this.resetForm();
          this.tab = "table";
        } catch (error) {
          console.error("Error al agregar el bin:", error);
        } finally {
          this.loading = false;
        }
      }
    },
    resetForm() {
      this.newBin.descripcion = "";
      this.newBin.numero = "";
      this.tab = "table";
    },
  },
};
</script>

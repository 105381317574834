<template>
  <v-dialog
    v-model="internalDialog"
    max-width="500px"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title
        class="font-weight-bold"
        style="font-family: 'Montserrat' !important;"
      >
        {{ promocion.nombre }}
      </v-card-title>
      <v-divider></v-divider>
      <div class="p-3 text-start">
        <div class="text-base d-flex  mb-2">
          <div class="w-50 opacity-80">
            Porcentaje:
          </div>
          <span class="font-bold ml-5">{{ promocion.porcentaje + "%" }}</span>
        </div>

        <div class="text-base d-flex items-center mb-2">
          <div class="w-50 opacity-80">
            Sin interés:
          </div>
          <v-chip
            class="ml-5"
            dark
            :color="promocion.cuota_sin_interes ? 'green' : 'red'"
          >
            {{ promocion.cuota_sin_interes ? "Si" : "No" }}
          </v-chip>
        </div>

        <div class="text-base d-flex ">
          <div class="w-50 opacity-80">
            Cuotas:
          </div>

          <span class="font-bold ml-5">
            {{ promocion.cuotas.map((c) => c.cuota).join(", ") }}
          </span>
        </div>

        <p class="font-bold  mt-5 mb-1 mx-1 text-base">
          Bines seleccionados
        </p>
        <v-simple-table fixed-header class="border" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left !py-2">
                  Descripción
                </th>
                <th class="text-left !py-2">
                  Bin
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in promocion.bines" :key="item.id">
                <td class="!py-2">{{ item.descripcion }}</td>
                <td class="!py-2">{{ item.numero }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VerDetalle",
  props: {
    promocion: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      internalDialog: this.dialog,
    };
  },
};
</script>
